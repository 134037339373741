import { Component, OnInit, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TeacherService } from '../../administration/teachers/services/teacher.service';
import { TeacherInterface } from '../../administration/teachers/interface/teacher.interface';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { SavingInterface } from '../../administration/savings/interfeces/saving.interface';
import { LoanInterface } from '../../administration/loans/interfaces/loan.interface';
import { PaymentInterface } from '../../administration/payments/interfaces/payment.interface';
import { OpenDirective } from '../../modals/open.directive';
import { AddQuestionComponent } from './add-question.component';
import { QuestionInterface } from '../../administration/loans/interfaces/question.interface';
import { AccountService } from '../../account/login/services/account.service';
import { LoanService } from '../../administration/loans/services/loan.service';
import { SavingService } from '../../administration/savings/services/saving.service';
import { PeriodInterface } from '../../../interfaces/period.interface';
import { ApplicationInterface } from '../../configuration/applications/interface/application.interface';
import { PaymentService } from '../../administration/payments/services/payment.service';
import { ConfigurationsInterface } from '../../configuration/configurations/interfaces/configurations.interface';
import { ConfigurationsService } from '../../configuration/configurations/services/configurations.service';
import { CurrencyPipe } from '@angular/common';
import { NotificationService } from '../../../../app/services/notification.service';
import { HelperService } from '../../../../app/services/helper.service';
import { SelectOption } from '../../../../app/interfaces/select-option.interface';
import { InputTypes } from '../../../../app/enums/sweetalert.enum';

declare let jsPDF;

@Component({
  selector: 'app-teacher-global-status',
  templateUrl: './teacher-global-status.component.html',
  styles: []
})
export class TeacherGlobalStatusComponent implements OnInit {
  errorMessage: string;
  @ViewChild(OpenDirective, { static: true }) adHost: OpenDirective;
  loadingData = false;
  statusId = '00000000-0000-0000-0000-000000000000';
  forma: UntypedFormGroup;
  formaLoans: UntypedFormGroup;
  savings: SavingInterface[];
  loans: LoanInterface[];
  payments: PaymentInterface[];
  percentageInterest = 0.00208;
  showLoansData = false;
  processingSaving = false;
  fullPayments = 0;
  incompletePayments = 0;
  totalToPay = 0;
  amountOfPayments = 0;
  condonedBalance = 0;
  year = 0;
  fortnight = 0;
  pathReturn = '/reports/teachers-global';
  amount = 0;
  amountInterests = 0;
  date: Date;
  type = 'status';
  years: number[] = [];
  fortnights: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];
  applications: ApplicationInterface[];
  question: QuestionInterface = {
    userId: this.accountService.getUserId(),
    comment: '',
    loanId: '00000000-0000-0000-0000-000000000000',
    teacherId: '00000000-0000-0000-0000-000000000000',
    payment: 0,
    saving: 0,
    date: new Date()
  };
  teacher: TeacherInterface = {
    id: '00000000-0000-0000-0000-000000000000',
    userId: '00000000-0000-0000-0000-000000000000',
    date: new Date(),
    active: true,
    rfc: '',
    name: '',
    street: '',
    number: '',
    colony: '',
    zipCode: 0,
    locality: '',
    email: '',
    admission: '',
    birthdate: '',
    contributionId: '00000000-0000-0000-0000-000000000000',
    gender: '',
    identifier: 0,
    amountSavings: 0,
    lastStatus: 'Activo',
    applied: true,
    credit: 0,
    lastUpdated: new Date,
    type: '',
    fortnight: '',
    userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
    regionId: '00000000-0000-0000-0000-000000000000',
    clabe: '',
    curp: '',
    bankId: '00000000-0000-0000-0000-000000000000',
    member: true,
    amountSavingsFortnight: 0,
    document: 0,
    documentNumber: '',
    NumberOfLoans: 0,
    bank: {
      id: '00000000-0000-0000-0000-000000000000',
      userId: '00000000-0000-0000-0000-000000000000',
      date: new Date(),
      active: false,
      lastUpdated: new Date(),
      userIdLastUpdated: '00000000-0000-0000-0000-000000000000',
      name: '',
      code: ''
    },
    contribution: {},
    teacherStatus: [],
    beneficiaries: [],
    teacherPhones: [],
    contributionPercentages: [],
    loans: [],
    savings: [],
    financialliquidity: [],
    payments: [],
    supports: [],
    lowStaffing: [],
    itIsSupplier: false
  };
  configurations: ConfigurationsInterface =  {
    id: '00000000-0000-0000-0000-000000000000',
    userId: this.accountService.getUserId(),
    date: new Date(),
    active: true,
    lastUpdated: new Date,
    userIdLastUpdated: this.accountService.getUserId(),
    imageFOCAPS: '',
    imageSNTE: '',
    messagePDFSavings: '',
    messagePDF: '',
    nextCheck: 0,
    nextCheckLowStaff: 0
  };
  loadingLoans = false;

  constructor( private activatedRoute: ActivatedRoute,
    private router: Router,
    private teacherService: TeacherService,
    private savingService: SavingService,
    private formBuilder: UntypedFormBuilder,
    private componentFactoryResolver: ComponentFactoryResolver,
    public accountService: AccountService,
    private loanService: LoanService,
    private paymentService: PaymentService,
    private configurationsService: ConfigurationsService,
    private currencyPipe: CurrencyPipe,
    private notificationService: NotificationService,
    private helperService: HelperService ) { }

  ngOnInit() {
    this.formaLoans = new UntypedFormGroup({
      year: new UntypedFormControl( '' ), // new Date().getFullYear() ),
      fortnight: new UntypedFormControl( '' ) // this.getNumberFortnight() )
    });

    this.accountService.getApplications('reports', 'teachers-global')
      .subscribe(applications => {
        this.applications = applications;
        this.activatedRoute.params.subscribe(params => {
          this.loadingData = true;
          this.configurationsService.get().subscribe(configurations => {
            this.configurations = configurations;
            this.paymentService.getYears().subscribe(years => {
              this.years = years;
              if ( params['year'] && params['fortnight'] && params['pathReturn'] ) {
                this.year = params['year'];
                this.fortnight = params['fortnight'];
                this.pathReturn = params['pathReturn'];
              }
              if (params['id']) {
                this.loadData(params['id']);
              } else {
                this.router.navigate(['reports/teachers-global']);
              }
            }, error => this.helperService.setErrorMessage(error));
          }, error => this.helperService.setErrorMessage(error));
        });
      }, error => this.helperService.setErrorMessage(error));
  }

  loadData(teacherId: string): void {
    this.teacherService.getById( teacherId ).subscribe(
      teacher => {
        this.teacher = teacher;
        const statusId = this.teacher.teacherStatus.filter(s => s.active === true)[0].id;
        this.statusId = statusId;
        this.forma = this.formBuilder.group({
          status: [statusId, [Validators.required]]
        });
        this.searchData(statusId);
        this.teacher.financialliquidity.sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
        this.loadingData = false;
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  handlerStatus( statusId: string ): void {
    this.statusId = statusId;
    this.searchData(statusId);
  }

  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      if ( date >= new Date() ) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while ( findingFortnight === true );
    return numberFortnight.toString();
  }

  searchPeriod() {
    this.searchData(this.statusId);
  }

  searchData( statusId: string ) {
    this.loadingData = true;
    const period: PeriodInterface = {
      start: '0001-01-01',
      end: '0001-01-01'
    };

    if ( statusId === '00000000-0000-0000-0000-000000000000' ) {
      statusId = this.teacher.id;
      this.type = 'all';
    } else {
      this.type = 'status';
    }

    if ( this.date !== undefined && this.date.toString() !== '' ) {
      if ( this.isValidDate(this.date) ) {
        period.start = this.date.toString();
      }
    }

    this.savingService.getSavingStatus(this.type, statusId, period).subscribe(
      savings => {
        this.savings = savings;
        /* this.savings = this.teacher.savings
              .sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 )
              .filter(s => s.teacherStatus.id === statusId); */
          if ( this.type === 'all' ) {
            this.loans = this.teacher.loans
              .sort((a, b) => ( parseInt(a.draw.name, 0) > parseInt(b.draw.name, 0)) ? 1 : -1 );
          } else {
            this.loans = this.teacher.loans
              .sort((a, b) => ( parseInt(a.draw.name, 0) > parseInt(b.draw.name, 0)) ? 1 : -1 )
              .filter(l => l.teacherStatusId === statusId);
          }

          this.payments = this.teacher.payments.sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
          this.calculateAmount(this.savings, this.percentageInterest);
          const loanActive = this.loans.find(l => l.status === 'Activo');
          if ( loanActive  ) {
            this.getDataLoanActive( loanActive );
          }
          this.loadingData = false;
      },
      error => this.helperService.setErrorMessage(error)
    );
  }

  calculateSavings( percentage: number) {
    this.percentageInterest = percentage;
    this.calculateAmount(this.savings, this.percentageInterest);
  }

  calculateAmount(data: SavingInterface[], percentage: number) {
    this.amount = 0;
    this.amountInterests = 0;
    let interestWithImport = 0;

    data.forEach(year => {
      let interestPeritem = 0;
      let amountPeritem = 0;
      const arrayYear = Object.keys(year).map(function(key) {
        return [String(key), year[key]];
      });

      for (let index = 0; index < arrayYear.length; index++) {
        if ( arrayYear[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
          if ( arrayYear[index][1] !== 0 ) {
            const interestFortnight = (interestWithImport + arrayYear[index][1]) * percentage;
            interestPeritem += interestFortnight;
            interestWithImport += arrayYear[index][1] + interestFortnight;
            amountPeritem += arrayYear[index][1];
          }
        }
      }
      year.folio = parseFloat(interestPeritem.toFixed(2)).toString();
      this.amountInterests += parseFloat(year.folio);
      this.amount += amountPeritem;
    });
  }

  changeLoans( checked: boolean ) {
    if ( checked ) {
      this.loans = this.teacher.loans
                    .sort((a, b) => ( parseInt(a.draw.name, 0) > parseInt(b.draw.name, 0)) ? 1 : -1 );
      this.showLoansData = true;
    } else {
      this.loans = this.loans = this.teacher.loans
              .sort((a, b) => ( parseInt(a.draw.name, 0) > parseInt(b.draw.name, 0)) ? 1 : -1 )
              .filter(l => l.teacherStatusId === this.forma.controls['status'].value);
      this.showLoansData = false;
    }
  }

  showConfirm( id: string ) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(AddQuestionComponent);

    const viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<AddQuestionComponent>componentRef.instance).message = `Seguro que desea agregar la pregunta`;
    (<AddQuestionComponent>componentRef.instance).open();
    (<AddQuestionComponent>componentRef.instance).alertOutput
      .subscribe( (result: any) => {
        if ( result.saving !== 0 || result.saving !== 0 ) {
          this.question.loanId = id;
          this.question.comment = result.comment;
          this.question.payment = result.payment;
          this.question.saving = result.saving;
          this.loans.find(l => l.id === id ).questions.push(this.question);

          this.loanService.createQuestion( this.question )
            .subscribe((response) => {
              this.loadingData = true;
              this.loadData( this.teacher.id );
              this.loans.find(l => l.id === id ).questions.push(this.question);
            }, error => this.helperService.setErrorMessage(error));
        }
      }, error => this.helperService.setErrorMessage(error));
  }

  showConfirmPayment( payment: PaymentInterface ): void {
    this.notificationService.showConfirm(`¿Seguro que desea eliminar el pago ${ payment.type } del año ${ payment.year }?`)
    .then(result => {
      if(result.isConfirmed) {
        this.deletePayment( payment );
      }
    });
  }
  deletePayment( payment: PaymentInterface ): void {
    this.paymentService.delete( payment.id )
    .subscribe( (result: any) => {
      this.loadingData = true;
      this.loadData( this.teacher.id );
    }, error => this.helperService.setErrorMessage(error) );
  }
  formatDate(value: number): string {
    if (value.toString().length === 1) {
      return  `0${ value }`;
    }
    return value.toString();
  }

  getAmountCredit( loan: LoanInterface ): number {
    const suma = loan.payments
      // tslint:disable-next-line:max-line-length
      .reduce((sum, current) => sum + current.fortnight1 + current.fortnight2 + current.fortnight3 + current.fortnight4 + current.fortnight5 + current.fortnight6 + current.fortnight7 + current.fortnight8 + current.fortnight9 + current.fortnight10
      // tslint:disable-next-line:max-line-length
      + current.fortnight11 + current.fortnight12 + current.fortnight13 + current.fortnight14 + current.fortnight15 + current.fortnight16 + current.fortnight17 + current.fortnight18 + current.fortnight19 + current.fortnight20
      + current.fortnight21 + current.fortnight22 + current.fortnight23 + current.fortnight24, 0);
    return suma;
  }

  getDataLoanActive( loan: LoanInterface ): void {
    this.fullPayments = 0;
    this.incompletePayments = 0;
    this.condonedBalance = 0;
    const discount = loan.loanParameter.discount;
    this.totalToPay = this.getAmountLoan( loan );
    // loan.loanParameter.amount + loan.loanParameter.interests + loan.loanParameter.iva + loan.loanParameter.lifeInsurance;
    this.amountOfPayments = this.getAmountCredit( loan );
    loan.payments.forEach(year => {
      const arrayYear = Object.keys(year).map(function (key) {
        return [String(key), year[key]];
      });

      for (let index = 0; index < arrayYear.length; index++) {
        if ( arrayYear[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
          if ( arrayYear[index][1] !== 0 ) {
            if ( arrayYear[index][1] === discount ) {
              this.fullPayments++;
            } else {
              this.incompletePayments++;
            }
          }
        }
      }
    });

    if ( this.teacher.type === 'Alta Jubilación' ) {
      // tslint:disable-next-line:max-line-length
      this.condonedBalance = ((loan.loanParameter.fortnight.numberFortnight / 2 ) - this.fullPayments) * loan.loanParameter.fortnightCapital;
    } else {
      this.condonedBalance = (loan.loanParameter.fortnight.numberFortnight - this.fullPayments) * loan.loanParameter.fortnightCapital;
    }
  }

  getPDFSaving( ) {
    const totalPagesExp = '{total_pages_count_string}';
    const head = this.getHeader();
    const body: any[] = this.setBodySavingData( );
    this.generatePDFSavings(head,
      body, totalPagesExp,
      this.configurations.imageSNTE,
      this.configurations.imageFOCAPS,
      this.currencyPipe, this.teacher);
  }

  // tslint:disable-next-line: max-line-length
  generatePDFSavings(head: any[], body: any[], totalPagesExp: string, imageSNTE: string, imageFOCAPS: string, currencyPipe: CurrencyPipe, teacher: TeacherInterface): void {
    const doc = new jsPDF( 'landscape' );
    const pageContent = function (data) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if ( imageSNTE ) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
     }

     if ( imageFOCAPS ) {
       doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45 , 15, 30, 20);
     }
      // tslint:disable-next-line: max-line-length
      doc.text(`${ teacher.name } ( ${ teacher.rfc } )\nAHORROS`, data.settings.margin.left + 55, 20);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 40 },
      headStyles: {
        halign: 'center'
      },
      // columnStyles: {
      //   amount: {
      //     halign: 'right'
      //   },
      //   numberFortnight: {
      //     halign: 'right'
      //   },
      //   lifeInsurance: {
      //     halign: 'right'
      //   },
      //   capital: {
      //     halign: 'right'
      //   },
      //   interest: {
      //     halign: 'right'
      //   },
      //   iva: {
      //     halign: 'right'
      //   },
      //   total: {
      //     halign: 'right'
      //   },
      // },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`ahorros_${ teacher.rfc }.pdf`);
  }

  setBodySavingData( ): any[] {
    const body = [];
    this.savings.forEach(item => {
      body.push({
        year: item.year,
        qna113: `${ this.currencyPipe.transform( item.fortnight1 ) }\n${ this.currencyPipe.transform( item.fortnight13 ) }`,
        qna214: `${ this.currencyPipe.transform( item.fortnight2 ) }\n${ this.currencyPipe.transform( item.fortnight14 ) }`,
        qna315: `${ this.currencyPipe.transform( item.fortnight3 ) }\n${ this.currencyPipe.transform( item.fortnight15 ) }`,
        qna416: `${ this.currencyPipe.transform( item.fortnight4 ) }\n${ this.currencyPipe.transform( item.fortnight16 ) }`,
        qna517: `${ this.currencyPipe.transform( item.fortnight5 ) }\n${ this.currencyPipe.transform( item.fortnight17 ) }`,
        qna618: `${ this.currencyPipe.transform( item.fortnight6 ) }\n${ this.currencyPipe.transform( item.fortnight18 ) }`,
        qna719: `${ this.currencyPipe.transform( item.fortnight7 ) }\n${ this.currencyPipe.transform( item.fortnight19 ) }`,
        qna820: `${ this.currencyPipe.transform( item.fortnight8 ) }\n${ this.currencyPipe.transform( item.fortnight20 ) }`,
        qna921: `${ this.currencyPipe.transform( item.fortnight9 ) }\n${ this.currencyPipe.transform( item.fortnight21 ) }`,
        qna1022: `${ this.currencyPipe.transform( item.fortnight10 ) }\n${ this.currencyPipe.transform( item.fortnight22 ) }`,
        qna1123: `${ this.currencyPipe.transform( item.fortnight11 ) }\n${ this.currencyPipe.transform( item.fortnight23 ) }`,
        qna1224: `${ this.currencyPipe.transform( item.fortnight12 ) }\n${ this.currencyPipe.transform( item.fortnight24 ) }`,
        subtotal: `${ this.currencyPipe.transform( item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5
          + item.fortnight6 + item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11
          + item.fortnight12 + item.fortnight13 + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17
          + item.fortnight18 + item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23
          + item.fortnight24 ) }\n${ item.folio  }`
      });
    });

    body.push({
      year: 'Subtotal',
      subtotal: this.currencyPipe.transform( this.amount )
    });

    body.push({
      year: 'Interes',
      subtotal: this.currencyPipe.transform( this.amountInterests )
    });

    body.push({
      year: 'Total',
      subtotal: this.currencyPipe.transform( this.amount + this.amountInterests )
    });

    body[body.length - 1].year = { content: `Total`,
      colSpan: 13, styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 2].year = { content: `Interes`,
      colSpan: 13, styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 3].year = { content: `Subtotal`,
      colSpan: 13, styles: { halign: 'right', fontStyle: 'bold' } };
    // tslint:disable-next-line: max-line-length
    body[body.length - 1].subtotal = { content: this.currencyPipe.transform( this.amount + this.amountInterests ) ,
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 2].subtotal = { content: this.currencyPipe.transform( this.amountInterests ),
      styles: { halign: 'right', fontStyle: 'bold' } };

    // tslint:disable-next-line: max-line-length
    body[body.length - 3].subtotal = { content:  this.currencyPipe.transform( this.amount ),
      styles: { halign: 'right', fontStyle: 'bold' } };

    return body;
  }

  getPDF( loan: LoanInterface ) {
    const totalPagesExp = '{total_pages_count_string}';
    const head = this.getHeader();
    const body = this.setBodyData( loan );
    this.generatePDF(head,
      body, totalPagesExp,
      this.configurations.imageSNTE,
      this.configurations.imageFOCAPS, loan,
      this.currencyPipe, this.teacher);
  }
  // tslint:disable-next-line: max-line-length
  generatePDF(head: any[], body: any[], totalPagesExp: string, imageSNTE: string, imageFOCAPS: string, loan: LoanInterface, currencyPipe: CurrencyPipe, teacher: TeacherInterface): void {
    const doc = new jsPDF( 'landscape' );
    const pageContent = function (data) {
      // HEADER
      doc.setFontSize(10);
      doc.setTextColor(40);
      doc.setFontStyle('normal');
      if ( imageSNTE ) {
        doc.addImage(imageSNTE, 'JPEG', data.settings.margin.left, 15, 30, 20);
     }

     if ( imageFOCAPS ) {
       doc.addImage(imageFOCAPS, 'JPEG', doc.internal.pageSize.width - 45 , 15, 30, 20);
     }
      // tslint:disable-next-line: max-line-length
      doc.text(`${ teacher.name } ( ${ teacher.rfc } )\nSorteo ${ loan.draw.name } Monto: ${ currencyPipe.transform( loan.amount ) } # Hoja: ${ loan.loanNumber } Cheque / Transferencia: ${ loan.checkNumber } \nQna Inicial: ${ loan.fortnightStart } Qna Final: ${ loan.fortnightEnd } Estatus: ${ loan.status } Fecha de aplicación: ${ loan.acceptanceDate }`, data.settings.margin.left + 55, 20);

      // FOOTER
      let str = 'Página ' + data.pageCount;
      // Total page number plugin only available in jspdf v1.0+
      if (typeof doc.putTotalPages === 'function') {
        str = str + ' de ' + totalPagesExp;
      }

      doc.setFontSize(10);
      doc.text(str, data.settings.margin.left, doc.internal.pageSize.height - 10);
    };

    doc.autoTable({
      head: head,
      body: body,
      didDrawPage: pageContent,
      margin: { top: 40 },
      headStyles: {
        halign: 'center'
      },
      // columnStyles: {
      //   amount: {
      //     halign: 'right'
      //   },
      //   numberFortnight: {
      //     halign: 'right'
      //   },
      //   lifeInsurance: {
      //     halign: 'right'
      //   },
      //   capital: {
      //     halign: 'right'
      //   },
      //   interest: {
      //     halign: 'right'
      //   },
      //   iva: {
      //     halign: 'right'
      //   },
      //   total: {
      //     halign: 'right'
      //   },
      // },
      theme: 'grid'
    });
    // Total page number plugin only available in jspdf v1.0+
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp);
    }
    doc.save(`prestamo_${ loan.draw.name }.pdf`);
  }

  setBodyData(loan: LoanInterface): any[] {
    const body = [];
    loan.payments.forEach(item => {
      body.push({
        year: item.year,
        qna113: `${ this.currencyPipe.transform( item.fortnight1 ) }\n${ this.currencyPipe.transform( item.fortnight13 ) }`,
        qna214: `${ this.currencyPipe.transform( item.fortnight2 ) }\n${ this.currencyPipe.transform( item.fortnight14 ) }`,
        qna315: `${ this.currencyPipe.transform( item.fortnight3 ) }\n${ this.currencyPipe.transform( item.fortnight15 ) }`,
        qna416: `${ this.currencyPipe.transform( item.fortnight4 ) }\n${ this.currencyPipe.transform( item.fortnight16 ) }`,
        qna517: `${ this.currencyPipe.transform( item.fortnight5 ) }\n${ this.currencyPipe.transform( item.fortnight17 ) }`,
        qna618: `${ this.currencyPipe.transform( item.fortnight6 ) }\n${ this.currencyPipe.transform( item.fortnight18 ) }`,
        qna719: `${ this.currencyPipe.transform( item.fortnight7 ) }\n${ this.currencyPipe.transform( item.fortnight19 ) }`,
        qna820: `${ this.currencyPipe.transform( item.fortnight8 ) }\n${ this.currencyPipe.transform( item.fortnight20 ) }`,
        qna921: `${ this.currencyPipe.transform( item.fortnight9 ) }\n${ this.currencyPipe.transform( item.fortnight21 ) }`,
        qna1022: `${ this.currencyPipe.transform( item.fortnight10 ) }\n${ this.currencyPipe.transform( item.fortnight22 ) }`,
        qna1123: `${ this.currencyPipe.transform( item.fortnight11 ) }\n${ this.currencyPipe.transform( item.fortnight23 ) }`,
        qna1224: `${ this.currencyPipe.transform( item.fortnight12 ) }\n${ this.currencyPipe.transform( item.fortnight24 ) }`,
        subtotal: this.currencyPipe.transform( item.fortnight1 + item.fortnight2 + item.fortnight3 + item.fortnight4 + item.fortnight5
          + item.fortnight6 + item.fortnight7 + item.fortnight8 + item.fortnight9 + item.fortnight10 + item.fortnight11
          + item.fortnight12 + item.fortnight13 + item.fortnight14 + item.fortnight15 + item.fortnight16 + item.fortnight17
          + item.fortnight18 + item.fortnight19 + item.fortnight20 + item.fortnight21 + item.fortnight22 + item.fortnight23
          + item.fortnight24 )
      });
    });

    body.push({
      year: 'Saldo pendiente',
      subtotal: this.currencyPipe.transform( this.getAmountLoan( loan ) - this.calculateGrandTotal( loan ) )
    });

    body.push({
      year: 'Abonos',
      subtotal: this.currencyPipe.transform( this.calculateGrandTotal( loan ) )
    });

    body.push({
      year: 'Total a pagar',
      subtotal: this.currencyPipe.transform( this.getAmountLoan( loan ) )
    });

    body[body.length - 1].year = { content: `Saldo pendiente`,
      colSpan: 13, styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 2].year = { content: `Abonos`,
      colSpan: 13, styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 3].year = { content: `Total a pagar`,
      colSpan: 13, styles: { halign: 'right', fontStyle: 'bold' } };
    // tslint:disable-next-line: max-line-length
    body[body.length - 1].subtotal = { content: this.currencyPipe.transform( this.getAmountLoan( loan ) - this.calculateGrandTotal( loan ) ) ,
      styles: { halign: 'right', fontStyle: 'bold' } };

    body[body.length - 2].subtotal = { content: this.currencyPipe.transform( this.calculateGrandTotal( loan ) ),
      styles: { halign: 'right', fontStyle: 'bold' } };

    // tslint:disable-next-line: max-line-length
    body[body.length - 3].subtotal = { content:  this.currencyPipe.transform( this.getAmountLoan( loan ) ),
      styles: { halign: 'right', fontStyle: 'bold' } };

    return body;
  }

  getHeader(): any[] {
    return [{ year: 'Año', qna113: 'Qna 1\nQna 13', qna214: 'Qna 2\nQna 14', qna315: 'Qna 3\nQna 15', qna416: 'Qna 4\nQna 16',
    qna517: 'Qna 5\nQna 17', qna618: 'Qna 6\nQna 18', qna719: 'Qna 7\nQna 19', qna820: 'Qna 8\nQna 20', qna921: 'Qna 9\nQna 21',
    qna1022: 'Qna 10\nQna 22', qna1123: 'Qna 11\nQna 23', qna1224: 'Qna 12\nQna 24', subtotal: 'Subtotal' } ];
  }

  getPayments( payments: Array<PaymentInterface> ): Array<PaymentInterface> {
    // tslint:disable-next-line: max-line-length
    const data = ( this.formaLoans.controls['year'].value !== '' ) ? payments.filter(p => parseFloat( p.year.toString() ) <= parseFloat( this.formaLoans.controls['year'].value ) ) : payments;
    const dateFortnight = this.getDateFortnightFilter();
    const liquidaciones = payments.filter(p => new Date(p.movementDate) <= new Date( dateFortnight ) && p.type === 'Liquidación' );
    data.forEach(payment => {
      const arrayColumns = Object.keys(payment).map(function (key) {
        return [String(key), payment[key]];
      });
      const yearEvalueate = payment.year;
      if ( payment.loanId.toUpperCase() === 'AA291EE2-3608-49A5-B73F-BC42216046F4' ) {
        console.log( payment );
      }
      for (let index = 0; index < arrayColumns.length; index++) {
        if ( arrayColumns[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
          if ( this.formaLoans.controls['year'].value !== '' && this.formaLoans.controls['fortnight'].value !== '' ) {
            const fortnight = arrayColumns[index][0].slice(9, arrayColumns[index][0].toString().length );
            if ( parseFloat( yearEvalueate.toString() ) < parseFloat( this.formaLoans.controls['year'].value ) ) {
              break;
            }

            if ( parseFloat( yearEvalueate.toString() ) === parseFloat( this.formaLoans.controls['year'].value ) &&
              parseFloat( fortnight ) > parseFloat( this.formaLoans.controls['fortnight'].value ) ) {
                console.log( 'Entro al if del año' + fortnight );
                this.setFortnight( payment, parseFloat( fortnight ), 0 );
                // break;
            }
          }
          // amount += parseFloat(arrayColumns[index][1]);
        }
      }


      // for (let index = 0; index < arrayColumns.length; index++) {
      //   if ( arrayColumns[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
      //     const fortnight = arrayColumns[index][0].slice(9, arrayColumns[index][0].toString().length );

      //     if ( parseFloat( yearEvalueate.toString() ) < parseFloat( this.formaLoans.controls['year'].value ) ) {
      //       break;
      //     }

      //     if (parseFloat( yearEvalueate.toString() ) === parseFloat( this.formaLoans.controls['year'].value ) &&
      //         parseFloat( fortnight ) > parseFloat( this.formaLoans.controls['fortnight'].value ) ) {
      //       // parseFloat( fortnight ) > parseFloat( this.formaLoans.controls['fortnight'].value ) ) {
      //         // console.log( `Año: ${ yearEvalueate } Qna: ${ fortnight } Seleccion Año ${ this.formaLoans.controls['year'].value } Qna ${ this.formaLoans.controls['fortnight'].value }` );
      //         this.setFortnight( payment, parseInt( fortnight, 0 ), 0 );
      //     }
      //   }
      // }
    });
    // return data.concat( liquidaciones ).sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
    return data.sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
  }

  getPaymentsWithoutFlirting( payments: Array<PaymentInterface> ): Array<PaymentInterface> {
    return payments.filter( p => p.loanId === null ).sort((a, b) => (a.year.toString() > b.year.toString()) ? 1 : -1 );
  }

  isValidDate ( str ): boolean {
    // optional condition to eliminate the tricky ones
    // since chrome will prepend zeros (000...) to the string and then parse it
    const noSpace = str.replace(/\s/g, '');
    if ( noSpace.length < 3) {
      return false;
    }
    return Date.parse(noSpace) > 0;
  }

  async showConfirmChangeFortnight( payment: PaymentInterface , fortnight: number, amount: number ) {
    const response = await this.notificationService.showConfirmInput(
      `¿Seguro que desea módificar la quincena ${ fortnight } con el importe ${ this.currencyPipe.transform( amount ) }?`
      , 'Módificaicon de quincena',
      InputTypes.Number);
    if(response !== null) {
      const amountModified = parseFloat(response);
      this.setFortnight(payment, fortnight, amountModified);
      this.paymentService.putPayment( payment.id, payment )
      .subscribe( () => {
      }, error => this.helperService.setErrorMessage(error) );
    }
  }

  async showChangeLoan( payment: PaymentInterface ) {
    const inputOptions = this.teacher.loans.map(l => ({
      value: l.id,
      text: `Sorteo: ${l.draw.name} Monto: ${this.currencyPipe.transform(l.amount)} # Hoja: ${l.loanNumber} # Identificación: ${l.checkNumber}`
    }) as SelectOption);

    const response = await this.notificationService.showConfirmSelect(
      `¿Seguro de asignar el pago a un prestamo diferente?`,
      inputOptions,
      'Asignar pago al prestamo',
      'Seleccione un prestamo',
      'Debe de seleccionar un prestamo');
    if(response !== null) {
      const loan = this.teacher.loans.find( l => l.id === response );
      loan.payments.push( payment );
      payment.loanId = response;
      this.paymentService.putPayment( payment.id, payment )
      .subscribe( () => {
      }, error => this.helperService.setErrorMessage(error) );
    }
  }

  calculateGrandTotal( loan: LoanInterface ): number {
    const dateFortnight = this.getDateFortnightFilter();
    let amount = 0; // loan.payments.reduce((sum, current) => sum + , 0);
    if ( this.formaLoans.controls['year'].value !== '' && this.formaLoans.controls['fortnight'].value !== '' ) {
      loan.payments.forEach(payment => {
        const arrayColumns = Object.keys(payment).map(function (key) {
          return [String(key), payment[key]];
        });
        const yearEvalueate = payment.year;
        if ( payment.type === 'Retención' ) {
          for (let index = 0; index < arrayColumns.length; index++) {
            if ( arrayColumns[index][0].slice(0, 9).toUpperCase() === 'FORTNIGHT' ) {
              const fortnight = arrayColumns[index][0].slice(9, arrayColumns[index][0].toString().length );
              if ( parseFloat( yearEvalueate.toString() ) === parseFloat( this.formaLoans.controls['year'].value ) &&
                parseFloat( fortnight ) > parseFloat( this.formaLoans.controls['fortnight'].value ) ) {
                break;
              }
              amount += parseFloat(arrayColumns[index][1]);
            }
          }
        } else if (payment.type === 'Liquidación' && new Date( payment.movementDate) <= new Date( dateFortnight ) ) {
          amount += payment.fortnight1 + payment.fortnight2 + payment.fortnight3 + payment.fortnight4 + payment.fortnight5 +
          payment.fortnight6 + payment.fortnight7 + payment.fortnight8 + payment.fortnight9 + payment.fortnight10 +
          payment.fortnight11 + payment.fortnight12 + payment.fortnight13 + payment.fortnight14 + payment.fortnight15 +
          payment.fortnight16 + payment.fortnight17 + payment.fortnight18 + payment.fortnight19 + payment.fortnight20 +
          payment.fortnight21 + payment.fortnight22 + payment.fortnight23 + payment.fortnight24;
        }
      });
    } else {
      // tslint:disable-next-line: max-line-length
      amount = loan.payments.reduce((sum, current) => sum + current.fortnight1 + current.fortnight2 + current.fortnight3 + current.fortnight4 + current.fortnight5 + current.fortnight6 + current.fortnight7 + current.fortnight8 + current.fortnight9 + current.fortnight10 + current.fortnight11 + current.fortnight12 + current.fortnight13 + current.fortnight14 + current.fortnight15 + current.fortnight16 + current.fortnight17 + current.fortnight18 + current.fortnight19 + current.fortnight20 + current.fortnight21 + current.fortnight22 + current.fortnight23 + current.fortnight24, 0);
    }

    return amount;
  }

  setFortnight( payment: PaymentInterface, fortnight: number, amount: number ): void {
    console.log( fortnight );
    switch ( fortnight ) {
      case 1:
        payment.fortnight1 = amount;
        break;
      case 2:
        payment.fortnight2 = amount;
        break;
      case 3:
        payment.fortnight3 = amount;
        break;
      case 4:
        payment.fortnight4 = amount;
        break;
      case 5:
        payment.fortnight5 = amount;
        break;
      case 6:
        payment.fortnight6 = amount;
        break;
      case 7:
        payment.fortnight7 = amount;
        break;
      case 8:
        payment.fortnight8 = amount;
        break;
      case 9:
        payment.fortnight9 = amount;
        break;
      case 10:
        payment.fortnight10 = amount;
        break;
      case 11:
        payment.fortnight11 = amount;
        break;
      case 12:
        payment.fortnight12 = amount;
        break;
      case 13:
        payment.fortnight13 = amount;
        break;
      case 14:
        payment.fortnight14 = amount;
        break;
      case 15:
        payment.fortnight15 = amount;
        break;
      case 16:
        payment.fortnight16 = amount;
        break;
      case 17:
        payment.fortnight17 = amount;
        break;
      case 18:
        payment.fortnight18 = amount;
        break;
      case 19:
        payment.fortnight19 = amount;
        break;
      case 20:
        payment.fortnight20 = amount;
        break;
      case 21:
        payment.fortnight21 = amount;
        break;
      case 22:
        payment.fortnight22 = amount;
        break;
      case 23:
        payment.fortnight23 = amount;
        break;
      case 24:
        payment.fortnight24 = amount;
        break;
    }
  }

  async showChangeFortnight( loan: LoanInterface) {
    let fortnights = this.getFortnights(50);
    const inputOptions = fortnights.map(f => ({
      value: f,
      text: f
    }) as SelectOption);

    const response = await this.notificationService.showConfirmSelect(
      `¿Seguro que desea módificar la quincena del prestamo ${ loan.loanNumber }?`,
      inputOptions,
      'Modificar quincena final',
      'Seleccione una quincena',
      'Debe de seleccionar una quincena');
    if(response !== null) {
      loan.fortnightEnd = response;
      this.loanService.update(loan.id, loan)
      .subscribe(() => {},
        error => this.helperService.setErrorMessage(error));
    }
  }

  getFortnights(numberFortnights: number): string[] {
    const arrFortnight = [];
    let counter = 1;
    let numberFortnight = 1;
    const date = new Date( new Date().getFullYear(), 0, 15 );
    do {
      arrFortnight.push( date.getFullYear().toString() + '-' + this.setFormatDay(numberFortnight) );
      if ( date >= new Date() ) {
        counter++;
      }
      numberFortnight++;
      if ( numberFortnight === 25 ) {
        numberFortnight = 1;
      }
      this.getDateFortnight(date);
    } while ( counter <= numberFortnights );
    return arrFortnight;
  }

  setFormatDay(day: number): string {
    if ( day.toString().length === 1 ) {
      return '0' + day.toString();
    }
    return day.toString();
  }

  getDateFortnight(date: Date): void {
    if ( date.getDate() === 15 ) {
      date.setMonth( date.getMonth() + 1 );
      date.setDate( 1 );
      date.setDate(date.getDate() - 1 );
    } else {
      date.setDate( date.getDate() + 15 );
    }
  }

  getAmountLoan( loan: LoanInterface ): number {
    if ( loan.loanParameter.prefix.startsWith( 'P' ) ) {
      const fortnights = loan.loanParameter.fortnight.numberFortnight / 2;
      // tslint:disable-next-line: max-line-length
      return (loan.loanParameter.fortnightCapital * fortnights ) +
        (loan.loanParameter.fortnightInterest * fortnights ) +
        (loan.loanParameter.fortnightIVA * fortnights ) +
        (loan.loanParameter.fortnightLifeInsurance * fortnights);
    } else {
      // tslint:disable-next-line: max-line-length
      return (loan.loanParameter.fortnightCapital * loan.loanParameter.fortnight.numberFortnight) +
        (loan.loanParameter.fortnightInterest * loan.loanParameter.fortnight.numberFortnight) +
        (loan.loanParameter.fortnightIVA * loan.loanParameter.fortnight.numberFortnight) +
        (loan.loanParameter.fortnightLifeInsurance * loan.loanParameter.fortnight.numberFortnight);
    }
  }

  searchLoans(): void {
    this.loadingLoans = true;
    this.loans = [];
    const date = this.getDateFortnightFilter();
    this.loans = this.teacher.loans
      .sort((a, b) => ( parseInt(a.draw.name, 0) > parseInt(b.draw.name, 0)) ? 1 : -1 )
      .filter(l => new Date( l.acceptanceDate ) <= new Date( date ) );

    const loanActive = this.loans.find(l => l.status === 'Activo');
    if ( loanActive  ) {
      this.getDataLoanActive( loanActive );
    }
    this.loadingLoans = false;
  }

  getDateFortnightFilter(): Date {
    const arrMonth = (this.formaLoans.controls['fortnight'].value / 2).toString().split('.');
    let date = new Date( this.formaLoans.controls['year'].value, parseInt( arrMonth[0], 0 ), 15 );
    if ( arrMonth.length === 1 ) {
      // tslint:disable-next-line: max-line-length
      date = new Date(this.formaLoans.controls['year'].value, parseInt( arrMonth[0], 0 ) - 1, new Date(date.getFullYear(), date.getMonth(), 0).getDate());
    }
    return date;
  }

}
