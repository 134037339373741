import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PaymentMovementsService } from '../services/payment-movements.service';
import { HelperService } from '../../../../services/helper.service';
import { ExcelService } from '../../../../services/excel.service';

@Component({
  selector: 'app-payments-movements',
  templateUrl: './payments-movements.component.html',
  styleUrls: ['./payments-movements.component.css']
})
export class PaymentsMovementsComponent implements OnInit {
  forma: FormGroup;
  currentPage = 1;
  itemsPerPage = 15;
  years: number[] = [];
  months: any[] = [
    {key: 0, value: 'Todo', fortnights: ['1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24']},
    {key: 1, value: 'Enero', fortnights: ['1', '2', '1,2']},
    {key: 2, value: 'Febrero', fortnights: ['3', '4', '3,4']},
    {key: 3, value: 'Marzo', fortnights: ['5', '6','5,6']},
    {key: 4, value: 'Abril', fortnights: ['7', '8', '7,8']},
    {key: 5, value: 'Mayo', fortnights: ['9', '10', '9,10']},
    {key: 6, value: 'Junio', fortnights: ['11', '12', '11,12']},
    {key: 7, value: 'Julio', fortnights: ['13', '14', '13,14']},
    {key: 8, value: 'Agosto', fortnights: ['15', '16', '15,16']},
    {key: 9, value: 'Septiembre', fortnights: ['17', '18','17,18']},
    {key: 10, value: 'Octubre', fortnights: ['19', '20', '19,20']},
    {key: 11, value: 'Noviembre', fortnights: ['21', '22', '21,22']},
    {key: 12, value: 'Diciembre', fortnights: ['23', '24', '23,24']},
  ];
  fortnights: string[] = [];
  isLoadingData = true;
  isGeneratingExcel = false;
  movements: any[] = [];
  totals: any[] = [];
  totalItems = 0;
  grandTotal = 0;
  header: string[] = [];
  detailedTemp = false;
  
  constructor(
    private paymentMovementsService: PaymentMovementsService,
    private formBuilder: FormBuilder,
    private helperService: HelperService,
    private excelService: ExcelService
  ) {}

  ngOnInit(): void {
    this.forma = this.formBuilder.group({
      detailed: ['false'],
      year: [new Date().getFullYear()],
      fortnight: [this.getNumberFortnight()],
      month: [new Date().getMonth() + 1]
    });
    this.onMonthChange();
    this.fillYears();
    this.loadData();
  }
  loadData(): void {
    this.isLoadingData = true;
    this.paymentMovementsService.get(this.detailed, this.year, this.fortnight, this.currentPage, this.itemsPerPage)
    .subscribe(data => {
      this.detailedTemp = this.detailed;
      this.movements = data.movements;
      this.totals = data.totals;
      this.totalItems = this.totals.reduce((sum, current) => sum + current.movements, 0)
      this.grandTotal = this.totals.reduce((sum, current) => sum + current.total, 0);
      this.header = this.fortnight.split(',');
      this.isLoadingData = !this.isLoadingData;    },
    (error) => this.helperService.setErrorMessage(error));
  }
  getNumberFortnight(): string {
    let findingFortnight = true;
    let numberFortnight = 1;
    const date = new Date(new Date().getFullYear(), 0, 15);
    do {
      if (date >= new Date()) {
        findingFortnight = false;
      } else {
        numberFortnight++;
        this.getDateFortnight(date);
      }
    } while (findingFortnight === true);
    return numberFortnight.toString();
  }
  getDateFortnight(date: Date): void {
    if (date.getDate() === 15) {
      date.setMonth(date.getMonth() + 1);
      date.setDate(1);
    } else {
      date.setDate(date.getDate() + 14);
    }
  }
  onMonthChange(): void {
    var currentMonth = this.months.find(x => parseInt(x.key) === parseInt(this.month.toString()));
    this.fortnights = currentMonth.fortnights;
    this.forma.get('fortnight').patchValue(this.fortnights[0])
  }
  fillYears(): void {
    for (let index = 2018; index <= new Date().getFullYear(); index++) {
      this.years.push(index);
    }
  }
  onPageChange(page): void {
    this.currentPage = page;
    this.loadData();
  }
  getExcel(): void {
    this.isGeneratingExcel = true;
    this.paymentMovementsService.get(this.detailed, this.year, this.fortnight, 1, this.totalItems)
    .subscribe(data => {
      this.excelService.exportAsExcelFile(data.movements, `movimientos_abonos_`);
      this.isGeneratingExcel = !this.isGeneratingExcel;
    },
    (error) => this.helperService.setErrorMessage(error));
  }

  get detailed(): boolean {
    return this.forma.get('detailed').value;
  }

  get year(): number {
    return this.forma.get('year').value;
  }

  get fortnight(): string {
    return this.forma.get('fortnight').value;
  }

  get month(): number {
    return this.forma.get('month').value;
  }
}
